.border{
  /* width: 243px;
  height: 35px; */
  border-radius: 18px;
  padding-left: 10px;
}
.btn {
  font-family: "Poppins-Bold";
  font-size: 15px;
  width: 172px;
  height: 37px;
  border-radius: 8px;
  border: none;
}
.labelpadding{
  padding-bottom: 5px;
}
.deletebtn {
  background-color: #e56e6e;
}
.updatebtn {
  margin-left: 11px;
  background-color: #006ecf;
}
/* .reasondiv {
  width: 31%;
  border-radius: 18px;
  border: 1px solid rgba(174, 174, 174, 1);
  padding: .3%;
  background-color: rgba(255, 255, 255, 1);

} */
