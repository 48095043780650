.footer{

    position: fixed;
    bottom: 0;
    left:0;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 1.5%;
    border: 1px solid rgba(222,222,222, 1);
}
   
  
 