.logo {
    padding-left: 27.7px;
}
.companyname{

color: rgba(54,54,54,1);
font-family: "Poppins-Bold";
font-size: 20px;
}

.headm {
    margin-top: 12px;
}
.headermain {

    border: 1px solid rgba(222, 222, 222, 1);
}
.search {
    padding-left: 2%;
}
.searchinput {
   
   width: 120%;
    color: #080808;
    font-family: "Poppins-Regular";
    font-size: 18px;
    border: none;
}
.sinput{
    border: none;
    width:184%;
    background-color:white
}
.notisty {
    padding: 0px 40.95px 0 0;
}
.logindetails {
    padding: 0px 20px 0px 20px;

    color: rgba(0, 0, 0, 1);
    font-family: "Poppins-Light";
    font-size: 12px;
    text-align: right;
}



.endlang {
   padding: 0px;
    margin-top: 11px;
}

.vr {
    color: rgb(130, 128, 128);
    height: 43px;
    margin-top: 1%;
}

.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option .side-navigation-panel-select-option-wrap .side-navigation-panel-select-option-text {

    color: rgba(54, 54, 54, 1);
    font-family: "Poppins-Bold";
    font-size: 15px;

    padding-right: 1rem;
}

.endownchev {
    padding-left: 3%;
    color: #006ECE;
}

.profilepic {
    padding-right: 6px;
}


.textsty {
    padding: 3% 4% 2% 4%;
    color: rgba(0, 110, 207, 1);
    font-family: "Poppins-SemiBold";
    font-size: 15px;
    border-radius: 5px;
    width: 25px;
    text-transform: uppercase;
    border: 1px solid rgba(0, 110, 207, 1);
}

@media only screen and (max-width: 1200px) {
    .headm {
        display: inline;
    }
  

    .notisty {
        width: 35px;
        padding:0px
     
    }

    .vr {
        display: none;
    }

    .logindetails {
        text-align: left;
        padding-left: 0%;

    }
  
    .endlang {
        padding: 0px;
    }
    .searchinput {

        width: 105px;
       
    }
}


.dropdown-toggle::after {
    display: none;
}