* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100vh;
}

.main {
  display: flex;
  height: 85.3vh
}

.sidebarr {

  flex-shrink: 0;
  border-right: 1px solid #DEDEDE;
  height: 100%;
  overflow: auto;
}

@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"),
    url("./fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: local("Poppins-SemiBold"),
    url("./fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Poppins-Light";
  src: local("Poppins-Light"),
    url("./fonts/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "Poppins-Thin";
  src: local("Poppins-Thin"),
    url("./fonts/Poppins-Thin.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Poppins-Medium";
  src: local("Poppins-Medium"),
    url("./fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"),
    url("./fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}