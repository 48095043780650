.bodydiv {
  padding: 22px 22px 0px 5px;
}
.tablesty {
  margin-top: 40px;
  text-align: center;
  border-radius: 5px 5px 0px 0px;
}
.leavetype {
  margin-top: 2%;
}
.btn {
  font-family: "Poppins-Bold";
  font-size: 15px;
  width: 172px;
  height: 37px;
  border-radius: 8px;
  border: none;
}
.deletebtn {
  background-color: #e56e6e;
}
.updatebtn {
  margin-left: 11px;
  background-color: #006ecf;
}

.cardstyle {
  float: left;
}

.cardhead {
  font-family: "Poppins-Medium";
  font-size: 16px;
  padding-top: 21px;
  padding-left: 3%;
}

.cardsubhead {
  font-family: "Poppins-Medium";
  /* font-size: 25px; */
  display: inline;
}

.btnview {
  height: 24px;
  width: 89px;
  color: rgba(255, 255, 255, 1);
  font-family: "Poppins-Bold";
  font-size: 10px;
  background-color: #006ecf;
  border: none;
  border-radius: 8px;
}

.available {
  color: rgba(71, 168, 31, 1);
  font-family: "Poppins-Medium";
  font-size: 12px;
  padding-top: 3%;
}

.matcardleave {
  padding-left: 7%;
  padding-right: 7%;
}

.matcont {
  color: rgba(54, 54, 54, 1);
  font-family: "Poppins-Regular";
  font-size: 14px;
  line-height: 14px;
}

.matcount1 {
  color: rgba(54, 54, 54, 1);
  font-family: "Poppins-Medium";
  font-size: 25px;

}

.matcount2 {
  color: rgba(244, 142, 142, 1);
  font-family: "Poppins-Regular";
  font-size: 12px;
}
