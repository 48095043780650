.bodydiv {
    padding: 22px 22px 0px 54.5px;
}

.container {
    display: flex;
    justify-content: flex-start;
}

.readmore {
    opacity: 1;
    color: rgba(0, 110, 207, 1);
    font-family: "Poppins-Bold";
    font-size: 18px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
    text-decoration: underline;
}

.replies {
    margin-top: 12px;
    padding-left: 30px;
}

.replyInfo {
    margin-top: 12px;
}

.flexbox {
    display: "flex";
    flex-direction: "column";
    /* justify-content: space-around; */
    padding-left: 1%;
    margin-bottom: 20px;
}

.commentbox {

    width: 521px;
    height: 129px;
    left: 986px;
    top: 650px;
    background: #FFFFFF;

}

.img-container {
    width: 63px;
    height: 63px;
    background-color: #D8D8D8;
    border-radius: 50%;
    overflow: hidden;
}

.round-img {
    width: 100%;
    height: auto;
    border-radius: 50%;
}

.subbtnn {
    Width: 89px;
    Height: 33px;
    margin-top: 15px;
    font-family: 'Poppins-Bold';
    font-size: 15px;
    border: none;
    display: flex;
   justify-content: center;
    background: #006ECF;
    border-radius: 6px;
    color: #FFFFFF;
   
    padding-top: .5%;
}

.postideabutton {
    opacity: 1;
    color: rgba(255, 255, 255, 1);
    font-family: "Poppins-Bold";
    font-size: 15px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
    text-align: center;
    line-height: 29px;
    margin-right: auto;
    margin-left: 0;
    width: 125px;
    height: 33px;
}

.postideasubheadings {
    color: rgba(54, 54, 54, 1);
    font-size: 12px;
    padding-top: 22px 0 0 0;
    font-family: "Poppins-Regular";
}

.mainheading {
    margin-top: 22px;
    color: rgba(54, 54, 54, 1);
    font-size: 25px;
    padding-top: 22px 0 0 0;
    font-family: "Poppins-Regular";
}

.subheading {

    color: rgba(54, 54, 54, 1);
    font-family: "Poppins-Regular";
    font-size: 15px;

    line-height: 29px;
}

/* .mybtn {
    padding-top: 13px;
} */


.myname {
    color: #006ECF;
    font-family: "Poppins-Bold";
    font-size: 15px;
    font-weight: 700;
    line-height: 22px;
   
   
}
.mynamesub {


    font-family: 'Poppins-Regular';
    font-size: 14px;
    line-height: 29px;

    align-items: center;
    color: #363636;
    padding-left: 2%;
   
    
    }
    .idea_descriptionstyle{
        font-family: 'Poppins-Regular';


font-size: 15px;
line-height: 29px;



color: #363636;
    }

.mydateandtime {
    color: rgba(54, 54, 54, 1);
    font-family: "Poppins-Regular";
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
}
.datefont{
   

font-family: 'Poppins-Regular';
font-size: 14px;
line-height: 21px;
display: flex;
align-items: center;
color: #363636;
}

.mytags {
    border-radius: 11px;
   text-align: center;
  padding-left: 13px;
  padding-right: 13px;
   padding-top: 0.5%;
   height: 21px;
    background-color: #CBEBB5;
    color: rgba(69, 127, 29, 1);
    font-family: "Poppins-Regular";
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
}

.mylargetext {
    color: rgba(54, 54, 54, 1);
    margin-top: 2.5px;
    font-family: "Poppins-Bold";
    font-size: 25px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
}

.mysmalltext {
    color: rgba(54, 54, 54, 1);
    font-family: "Poppins-Regular";
    font-size: 11px;
    font-weight: 400;
    font-style: normal;
}

.myreadmore {
    color: rgba(0, 110, 207, 1);
    font-family: "Poppins-Bold";
    font-size: 12px;
    font-weight: 700;
    text-decoration: underline;
}

.mytime {
    color: rgba(54, 54, 54, 1);
    font-family: "Poppins-Regular";
    font-size: 12px;
}




.btninput {
    width: 40%;
}

.truncate {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-family: "Poppins-Regular";
    font-size: 15px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
    line-height: 29px;
}

.myreadmore {
    display: block;
    margin-top: 5px;
    color: #4F86C6;
    font-weight: bold;
    text-decoration: none;
}

.myreadmore:hover {
    text-decoration: underline;
}

.postmodal {
    width: 1003px;
    height: 704px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    background: #FFFFFF;
    border-radius: 11px;
}


.truncate {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 10px;
}

.likebtn {
    margin-right: 5px;
    font-family: 'Poppins-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    align-items: center;
    color: #68A93B;
}

.dislikebtn {
    font-family: 'Poppins-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    align-items: center;
    color: #E56E6E;

}

.commentheading {
    font-family: 'Poppins-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    /* identical to box height, or 145% */
    color: #363636;
}

.comments {
    font-family: 'Poppins-Regular';
   font-weight: lighter;
    font-size: 14px;
    line-height: 29px;
    /* or 193% */


    color: #363636;
}

.ideadescription {

    left: 284px;
    margin-top: 22px;
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 29px;
    /* or 193% */
    color: #363636;
}

.commentboxdata {
    font-family: 'Poppins-Regular';
padding: 3%;
    font-size: 14px;
    line-height: 21px;

    color: #363636;
    box-sizing: border-box;

    width: 521px;
    height: 129px;
    left: 986px;
    top: 650px;

    background: #FFFFFF;

    border-radius: 7px;
}

.newcommentdisplay {
    width: 52px;
    height: 21px;
    text-align: center;
    left: 613px;
    top: 1029px;
    background: #A0F222;
    border-radius: 11px;
    font-family: 'Poppins-Regular';
    font-style: normal;
    padding-top: .3%;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;


color: #467300;
}

.newcommenttext {
    width: 26px;
    height: 18px;
    left: 613px;
    top: 1031px;
}

.numberofcomments {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 29px;
}

.btntext {

    width: 125px;
    height: 33px;
    left: 280px;
    top: 187px;
    background: #006ECF;
    border-radius: 6px;
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 29px;
    color: #FFFFFF;
}

.cmntrplydatetime {
    width: 124px;
    height: 17px;
    left: 476px;
    top: 664px;
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #363636;

}

