


/* VARIABLES */

:root {
    --main-color: #1a8fff;
  
    --text-color-light: #ccc;
    --border-color: #eee;
   
    --neutral-color: #fff;
  }
  
  /* GENERAL */
  
  * {
    box-sizing: border-box;
    
  }
  
  body {
    
    font-size: 1em;
   
    line-height: 1.5;
 
 
    position: relative;
  }
  
  
  
  header #logo {
    font-size: 175%;
    text-align: center;
    color: var(--main-color);
    line-height: 1;
  }
  
  header #logo .icon {
    padding-right: 0.25em;
  }
  
  main {
    display: block;
    margin: 0 auto;
    margin-top: 5em;
    max-width: 50em;
  }
  
  /* GRID */
  
  .rowsty {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  .leaveappliedclose{
margin-top: 40px;
width: 108px;
height: 38px;
   
    font-family: 'Poppins-Bold';
   
    font-size: 15px;
    line-height: 29px;
    /* or 193% */
    
   
    text-align: center;
    
    color: #FFFFFF;
  }
  
  .row-middle {
    align-items: center;
  }
  
  .col {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
   
  }
  
  .col-start {
    justify-content: flex-start;
    text-align: left;
  }
  .dayscell{
    font-family: 'Poppins-Regular';
    font-size: 15px;
    
    
    color: #363636;
  }
  .daycell{
    font-family: 'Poppins-Bold';
        
    font-size: 20px;

/* identical to box height, or 145% */



color: #363636;
  }
  .col-center {
    justify-content: left;
    padding-left: 20px;
    font-family: 'Poppins-Regular';
    color: #363636;
    font-size: 15px;
    border-right:1px solid  #D3D3D3;
    height: 29px;
  }
  
  .col-end {
    justify-content: flex-end;
    text-align: right;
  }
  
  /* calenderweek */
  
  .calendarweek {
    margin-top: 30px;
    display: block;
    position: relative;
    width: 100%;
    background: var(--neutral-color);
    border: 1px solid var(--border-color);
  }
  
  

 
  
  .calendarweek .body .daycell::after {
    content: attr(data-date);
    position: absolute;
    top: 5px;
    right: 5px;
    color: #212121;
    font-size: 18px;
}
.calendarweek .body .highlighted {

  &::after {
      color: #83C043;
      ;
  }

}
  /* .calenderweek .days {
  
    font-weight: 400;
    color: var(--text-color-light);
    font-size: 70%;
  
    border-bottom: 1px solid var(--border-color);
  } */
/*   
  .calenderweek .body .cell::after {
    position: relative;
    height: 4em;
    border-right: 1px solid var(--border-color);
    overflow: hidden;
    cursor: pointer;
    background: var(--neutral-color);
    transition: 0.25s ease-out;
 
    font-family: 'Poppins-Bold';

    font-size: 15px;
    line-height: 29px;
    
    
    
    color: #9d0a0a;
  } */
  /* .calenderweek .body .cell {
  &::after {
    color: #83C043;
    ;
}}
   */
  
  
  
  .calendarweek .body .today {
    border-left: 10px solid transparent;
    border-image: linear-gradient(45deg, #ff1a79 0%, #eb82b3 40%);
    border-image-slice: 1;
  }
  .calendarweek .body .row {
    border-bottom: 1px solid var(--border-color);
  }
  
  /* .calenderweek .body .row:last-child {
    border-bottom: none;
  } */
  
  .calendarweek .body .cell:last-child {
    border-right: 3px solid yellow;
  }
  
  
  
  
  
  /* .calenderweek .body .cell .bg {
    font-family: 'Poppins-Regular';

font-size: 15px;
line-height: 29px;


color: #e00e0e;
  
 
    position: absolute;
    top: -0.2em;
    right: -0.05em;
    transition: 0.25s ease-out;
    letter-spacing: -0.07em;
  }
   */
  
  
  .calendarweek .body .col {
    flex-grow: 0;
    flex-basis: calc(100% / 7);
    width: calc(100% / 7);
  }
    