.Input {
  width: 40%;
  border-radius: 17px;
}

.leavetype {
  margin-top: 2%;
}

.heading{
margin-top: 37px;
font-family: "Poppins-Bold";
font-size: 15px;
font-weight: 700;
}
.subheading{
font-family: "Poppins-Regular";
font-size: 15px;
font-weight: 400px;
margin-bottom: 13px;
padding-left: 4px;
}
.card {
  width: 639px;
  height: 134px;
  background-color: #fafafa;
  padding-bottom: 1%;
}

.border {
border-radius: 18px;
}



.calenderbtn {
background-color: #006ecf;
}

.btn {
  font-family: "Poppins-Bold";
  font-size: 15px;
  width: 172px;
  height: 37px;
  border-radius: 8px;
  border: none;
}
.labelpadding{
  padding-bottom: 5px;
}

.Todaydaysdisplay{
    align-items: center;
    margin-top: 30px;
    display: flex;
    justify-content: flex-start;
}

.submit {
  border-radius: 8px;
  width: 172px;
  height: 37px;
  background-color: #006ecf;
  color: #ffffff;
  line-height: 29px;
  font-size: 15px;
  font-family: "Poppins-Bold";
  border: none;
}

.divider {
  width: 1px;
  height: 50px;
  background-color: #000;
  margin: 0 10px;
}

.leavecalchead{
  font-size: 20px;
  font-weight: 500;
  white-space: nowrap;
}
.noofdays{
  font-size: 20px;
  font-weight: 900;
 
}




