.cardstyle {
    float: left;
  }
  
  .cardhead {
    font-family: "Poppins-Medium";
    font-size: 16px;
    padding-top: 21px;
    padding-left: 3%;
  }
  
  .cardsubhead {
    font-family: "Poppins-Medium";
    /* font-size: 25px; */
    display: inline;
  }
  
  .btnview {
    height: 24px;
    width: 89px;
    color: rgba(255, 255, 255, 1);
    font-family: "Poppins-Bold";
    font-size: 10px;
    background-color: #006ecf;
    border: none;
    border-radius: 8px;
  }
  
  .available {
    color: rgba(71, 168, 31, 1);
    font-family: "Poppins-Medium";
    font-size: 12px;
    padding-top: 3%;
  }
  .mb{
    margin-right: 20px;
  }
  
  .matcardleave {
    padding-left: 7%;
    padding-right: 7%;
  }
  
  .matcont {
    color: rgba(54, 54, 54, 1);
    font-family: "Poppins-Regular";
    font-size: 14px;
    line-height: 14px;
  }
  
  .matcount1 {
    color: rgba(54, 54, 54, 1);
    font-family: "Poppins-Medium";
    font-size: 25px;
  
  }
  
  .matcount2 {
    color: rgba(244, 142, 142, 1);
    font-family: "Poppins-Regular";
    font-size: 12px;
  }
  .flex{
    display: flex;
  }