
/* GENERAL */



/* body {




    color: #363636;


} */
.leavecalender{
    margin-bottom: 17px;
}

#btnsty.selected{
    background-color: green;
}


.mytimeweek {
    background-color: #006ECF;
    border-radius: 6px;
    color: rgba(255, 255, 255, 1);
    padding-left: 12px;
    padding-right: 12px;
    font-family: "Poppins-Bold";
    font-size: 15px;
    line-height: 29px;
    height: 37px;
}

.mybtns{
   
    padding-top: 19px;

}

.notestl {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    padding-left: 4%;
    line-height: 29px;
    /* identical to box height, or 193% */
    margin-top: 2%;
    text-align: right;

    color: #363636;
}

main {
    display: block;
    margin: 0 auto;
    margin-top: 5em;
    max-width: 50em;
}


/* GRID */

.week {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(7, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;
}
.hai{
    color: green;
}
.daycalender {
    display: grid;
    font-family: "Poppins-bold";

}


/* Calendar */
.proj {
    padding-right: 2%;
    padding-left: 1%;

    font-size: 15px;
    line-height: 22px;
    font-family: 'Poppins-Regular';
    /* identical to box height */


    color: #363636;
}

.calendar {


    box-sizing: border-box;





    background: #b70606;



    position: relative;
    width: 95%;
    background: #FFFFFF;
    border: 1px solid #D3D3D3;
    border-radius: 10px;
}

.curt_month {
    font-family: 'Poppins-Medium';
    font-style: normal;
    padding-left: 12%;
    font-size: 12px;
    line-height: 29px;
    /* identical to box height, or 242% */


    color: #363636;
}

.header {
    height: 45px;
    display: grid;
    grid-template-columns: 25px 1fr 25px;
    grid-template-areas: "--left --center --right";
    grid-column-gap: 0;
    width: 100%;
    border-bottom: 2px solid #c32323;

    .icon {
        cursor: pointer;
    }
}





.header-chevron.--left {
    grid-area: --left;
}

.header-chevron.--right {
    grid-area: --right;
}

.calendar .body {
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    grid-row-gap: 0;
}

.calendar .days {

    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 29px;
    color: #363636;
    user-select: none;
    display: grid;
    background-color: #F7F7F7;
    ;
    grid-template-columns: repeat(7, 1fr);
}

.day-of-week {
    padding: 8px 17px;
    text-align: left;
    font-family: 'Poppins-Regular';

    font-weight: 400;
    font-size: 15px;
    border-right: 1px solid #D3D3D3;

    &:last-of-type {
        border-right: none;
    }
}

.hourch {
    font-family: 'Poppins-Bold';
    font-style: normal;

    font-size: 25px;
    line-height: 45px;
    /* identical to box height */
    padding-top: 12px;


    color: #6AAAE2;
}

.hourch1 {
    font-family: 'Poppins-Regular';
    font-style: normal;

    font-size: 14px;
    line-height: 45px;
    /* identical to box height */
    padding-top: 12px;


    color: #6AAAE2;
}

.calendar .body .daycalender {
    position: relative;
    border-right: 1px solid #D3D3D3;

    overflow: hidden;
    background: var(--neutral-color);
}

.calendar .body .week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-column-gap: 0;
    height: 100px;
  
}

.calendar .body .day-of-week {
    &:last-child {
        border-bottom: none;
    }
}

.calendar .body .daycalender {
    padding: 15px 15px 5px;

    &:last-child {
        border-right: none;
    }
}

.calendar .body .daycalender::after {
    content: attr(data-date);
    position: absolute;
    top: 5px;
    right: 5px;
    color: #363636;
    font-size: 18px;
}
.currentweekstyle {
    color: rgba(54, 54, 54, 1);
    font-family: "Poppins-Medium";
    font-size: 12px;
    line-height: 29px;
    padding-top: 22.5px;
   
}
.curt_month {
    font-family: 'Poppins';
    font-style: normal;
  
    font-size: 12px;
    line-height: 29px;
    /* identical to box height, or 242% */


    color: #363636;
}

.calendar .body .disabled {
    pointer-events: none;
   

    &::after {
        color: #AEAEAE;
    }
}
.calendar .body .highlighted {
 
    &::after {
        color: #83C043;
        ;
    }
    
}
.vanish{
    display: none;
}

.PreselectedDates {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 0;
    margin: 0;
}
    /* li {
        list-style: none;
        background-color: #f9f9f9;
        padding: 15px 0;
        margin: 0;
        border-right: 1px solid #ddd;
        text-align: center;
        font-size: 12px;
        user-select: none;

        &:hover {
            cursor: pointer;
            background-color: #bbb;
            color: white;
        }

        &:last-of-type {
            border-right: none;
        }
    } */
