:root {
    --main-color: #1a8fff;

    --text-color-light: #b0a3a3;
    --border-color: #eee;

    --neutral-color: #fff;
}


/* GENERAL */

* {
    box-sizing: border-box;
}

body {
    /* font-family: 'Poppins';
    font-style: normal;
    font-weight: 700; */
    font-size: 20px;
    line-height: 29px;
    /* identical to box height, or 145% */

   

    color: #363636;

    line-height: 1.5;

    position: relative;

}

header {
    display: block;
    width: 100%;
    padding: 1.75em 0;
    border-bottom: 1px solid var(--border-color);
    background: var(--neutral-color);
}

header #logo {
    font-size: 175%;
    text-align: center;
    color: var(--main-color);
    line-height: 1;
}

header #logo .icon {
    padding-right: .25em;
}

main {
    display: block;
    margin: 0 auto;
    margin-top: 5em;
    max-width: 50em;
}


/* GRID */

.week {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(7, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;
}

.day {
    display: grid;
}


/* Calendar */

.calendar {


    box-sizing: border-box;





    background: #FFFFFF;
    border: 1px solid #D3D3D3;
    border-radius: 10px;

    position: relative;
    width: 90%;
    background: var(--neutral-color);


    box-shadow: 0 0 3px 0 rgba(0, 0, 0, .05);
    /* overflow: hidden; */
}
.curt_monthtime {
    font-family: 'Poppins-Regular';
    font-style: normal;
    
    font-size: 12px;
    line-height: 29px;
    /* identical to box height, or 242% */


    color: #363636;
}

.header {
    height: 45px;
    display: grid;
    grid-template-columns: 25px 1fr 25px;
    grid-template-areas: "--left --center --right";
    grid-column-gap: 0;
    width: 100%;
    border-bottom: 1px solid var(--border-color);

    .icon {
        cursor: pointer;
    }
}





.header-chevron.--left {
    grid-area: --left;
}

.header-chevron.--right {
    grid-area: --right;
}

.calendar .body {
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    grid-row-gap: 0;
}

.calendar .days {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 29px;
    /* identical to box height, or 193% */


    color: #363636;
    font-weight: 600;
    color: var(--text-color-dark);

    border-bottom: 1px solid var(--border-color);
    user-select: none;
    display: grid;
    background-color: #F7F7F7;
    ;
    grid-template-columns: repeat(7, 1fr);
}

.day-of-week {
    padding: 10px 0;
    text-align: center;
    border-right: 1px solid var(--border-color);

    &:last-of-type {
        border-right: none;
    }
}

.calendar .body .day {
    position: relative;
    border-right: 1px solid var(--border-color);
    overflow: hidden;
    background: var(--neutral-color);
}

.calendar .body .week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-column-gap: 0;
    height: 100px;
    border-bottom: 1px solid var(--border-color);
}

.calendar .body .day-of-week {
    &:last-child {
        border-bottom: none;
    }
}

.calendar .body .day {
    padding: 15px 15px 5px;

    &:last-child {
        border-right: none;
    }
}

.calendar .body .day::after {
    content: attr(data-date);
    position: absolute;
    top: 5px;
    right: 5px;
    color: #000;
    font-size: 12px;
}

.calendar .body .disabled {
    pointer-events: none;

    &::after {
        color: #ddd;
    }
}

.PreselectedDates {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 0;
    margin: 0;

  
}
.currentweekstylemonth{
    color: rgba(54,54,54,1);
    font-family: "Poppins-Medium";
    font-size: 12px;
    line-height: 29px;
    text-align: center;
  
}
