
    .currentweeksubmain_time{
        padding: 0 15px 0 15px;
    }
    .currentweeksub_time{
        
        color: rgba(54,54,54,1);
        font-family: "Poppins-Medium";
        font-size: 15px;
        line-height: 29px;
        }


        /* .mybtn_time {
            padding-top: 19px;
        } */