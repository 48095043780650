body{
    overflow-x: hidden;
    overflow-y: hidden;
}
.headinglogin{
    padding-top: 124px;
    color: rgba(0,122,179,1);
    font-family: "Poppins-SemiBold";
    font-size: 30px;
    text-align: center;  
}
.welcomelogin{
color: rgba(0,122,179,1);
font-family: "Poppins-Thin";
font-size: 60px;
text-align: center;
}
.googleimage{
    cursor: pointer;
}
.companyname{
    padding-top: 28px;
color: rgba(54,54,54,1);
font-family: "Poppins-Bold";
font-size: 20px;
padding-left: 95px;
border-bottom: 1px solid rgba(222,222,222, 1);
height: 98px;
}

.demo12{
   
    color: rgb(10, 122, 174);
    font-family: "Poppins-Regular";
    font-size: 20px;
    margin-top:40px;
    text-align: center;
}
.navbardesign{
    border-bottom:1px solid rgba(222,222,222, 1);
 height:86px
}
.page-footer{
    position: fixed;
    bottom: 0;
    left:0;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 1.5%;
    border: 1px solid rgba(222,222,222, 1);
}