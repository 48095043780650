.accordion-item:last-of-type .accordion-button.collapsed {
   background-color: white;
   border: none;
}

.accordion-item {
   border-bottom: none;
}

.accordion-body {
   padding-bottom: 1%;
   cursor: pointer;
}

.dashimg {
   padding: 0 0 1% 7.5%;
}

.dashb {
   padding-top: 12%;
   padding-bottom: 3%;
}

.accordion-item:first-of-type {
   border: none;
}

.accordion-item:not(:first-of-type) {
   border: none;
}

.dashcont {
   padding-left: 4%;
   padding-top: 4%;
   color: rgba(54, 54, 54, 1);
   font-family: "Poppins-Bold";
   font-size: 15px;
}

.accordion-body {
   text-decoration: none;
   padding-left: 24%;
   color: #363636;
   font-family: "Poppins-Bold";
   font-size: 13px;
   line-height: 44px;
}

.accordion-item {
   margin-top: 12px;
}

.accheaderbar {
   color: rgba(54, 54, 54, 1);
   font-family: "Poppins-Bold";
   font-size: 15px;
}