/* GENERAL */

* {
    box-sizing: border-box;

}

body {

    line-height: 1.5;
    position: relative;

}

header {
    display: block;
    width: 100%;
    padding: 1.75em 0;
    /* border-bottom: 1px solid #D3D3D3; */

}

header #logo {
    font-size: 175%;
    text-align: center;

    line-height: 1;
}

header #logo .icon {
    padding-right: .25em;
}

.mytimeweek {
    background-color: #006ECF;
  
    color: rgba(255, 255, 255, 1);
    padding-left: 12px;
    padding-right: 12px;
    font-family: "Poppins-Bold";
    font-size: 15px;
    line-height: 29px;
    display: inline;
    width: 45%;
    height: 37px;
}



main {
    display: block;
    margin: 0 auto;
    margin-top: 5em;
    max-width: 50em;
}


/* GRID */

.week {
    margin: 0;
    padding: 0;
    width: 1340px;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(7, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;
}

.hai {
    color: green;
}

.daymonth {
    display: grid;
    font-family: "Poppins-bold";

}


/* Calendar */
.proj {
    padding-right: 2%;
    padding-left: 1%;

    font-size: 15px;
    line-height: 22px;
    font-family: 'Poppins-Regular';
    /* identical to box height */


    color: #363636;
}

.calendermonth {


    box-sizing: border-box;



    position: relative;

    

    background: #FFFFFF;
    border: 1px solid #D3D3D3;
    border-radius: 17px;
}

.curt_month {
    font-family: 'Poppins-Medium';
    font-style: normal;
    padding-left: 12%;
    font-size: 12px;
    line-height: 29px;
    /* identical to box height, or 242% */


    color: #363636;
}

.header {
    height: 45px;
    display: grid;
    grid-template-columns: 25px 1fr 25px;
    grid-template-areas: "--left --center --right";
    grid-column-gap: 0;

    border-bottom:0;

    .icon {
        cursor: pointer;
    }
}





.header-chevron.--left {
    grid-area: --left;
}

.header-chevron.--right {
    grid-area: --right;
}

.calendermonth .body {
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    grid-row-gap: 0;
}
.onleavetitle{
    
font-family: 'Poppins-Regular';

font-size: 16px;
line-height: 24px;



color: #363636;
}
.calendermonth .days {

    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 29px;
    color: #363636;
    user-select: none;
    display: grid;
    background-color: #F7F7F7;
    ;
    grid-template-columns: repeat(7, 1fr);
}
.popupdate{

font-family: 'Poppins-Bold';
font-size: 20px;
line-height: 30px; 
color: #363636;
margin-top: 12px;
}
.day-of-week {

    text-align: left;
    font-family: 'Poppins-Regular';

    color: #363636;

    font-weight: 400;
    font-size: 15px;
    border-right: 1px solid #D3D3D3;

    &:last-of-type {
        border-right: none;
    }
}

.hourch {
    font-family: 'Poppins-Bold';
    font-style: normal;

    font-size: 25px;
    line-height: 45px;
    /* identical to box height */
    padding-top: 12px;


    color: #6AAAE2;
}

.hourch1 {
    font-family: 'Poppins-Regular';
    font-style: normal;

    font-size: 14px;
    line-height: 45px;
    /* identical to box height */
    padding-top: 12px;


    color: #6AAAE2;
}

.calendermonth .body .daymonth {
    position: relative;
    border-right: 1px solid #D3D3D3;

    overflow: hidden;
    background: var(--neutral-color);
}

.calendermonth .body .week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-column-gap: 0;
    height: 100px;
    border-bottom: 1px solid #D3D3D3;
}

.calendermonth .body .day-of-week {
    &:last-child {
        border-bottom: none;
    }
}

.calendermonth .body .daymonth {
    padding: 15px 15px 5px;

    &:last-child {
        border-right: none;
    }
}

.calendermonth .body .daymonth::after {
    content: attr(data-date);
    position: absolute;
    top: 5px;
    right: 5px;
    color: #363636;
    font-size: 18px;
}

/* div.currentweekstyle {
    color: rgba(54, 54, 54, 1);
    font-family: "Poppins-Medium";
    font-size: 12px;
    line-height: 29px;

    width: 290px;
} */

.curt_month {
    font-family: 'Poppins';
    font-style: normal;

    font-size: 12px;
    line-height: 29px;
    /* identical to box height, or 242% */


    color: #363636;
}

.calendermonth .body .disabled {
    pointer-events: none;


    &::after {
        color: #AEAEAE;
    }
}

.calendermonth .body .highlighted {

    &::after {
        color: #83C043;
        ;
    }

}
span.b {
    display: inline-block;

    background-color: yellow; 
  }
.noof_days{
    font-family: 'Poppins-Regular';
font-size: 16px;
line-height: 24px;
padding-top: 18px;

color: #363636;

}

.dt{
    font-family: 'Poppins-Regular';
padding-left: 48px;
font-size: 12px;
line-height: 18px;
/* identical to box height */


color: #363636;

}
.dte{
    font-family: 'Poppins-Regular';
  
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */
    
    
    color: #363636;
}
.vanish {
    display: none;
}

.PreselectedDates {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 0;
    margin: 0;
}

/* li {
    list-style: none;
    background-color: #f9f9f9;
    padding: 15px 0;
    margin: 0;
    border-right: 1px solid #ddd;
    text-align: center;
    font-size: 12px;
    user-select: none;

    &:hover {
        cursor: pointer;
        background-color: #bbb;
        color: white;
    }

    &:last-of-type {
        border-right: none;
    }
} */