.bodydiv{
padding: 22px 22px 0px 0px;

}


.tablestyleb{
    border-bottom: none;
}
.currentweeksub{
    
color: rgba(54,54,54,1);
font-family: "Poppins-Medium";
font-size: 15px;
line-height: 29px;
}
.currentweeksubmain{
    padding: 0 15px 0 15px;
}
.mainheading {
    color: rgba(54, 54, 54, 1);
    font-size: 25px;
    padding-top: 22px 0 0 0;
    font-family: "Poppins-Regular";
}
.currentweekstyle{
    color: rgba(54,54,54,1);
    font-family: "Poppins-Medium";
    font-size: 12px;
    line-height: 29px;
  
}
.tablesty{
    margin-top: 40px;
    text-align: center;
    border-radius: 5px 5px 0px 0px;
}
.tableheadstyle{
     height: 49px;
    
background-color: rgba(243,243,243, 1);
}
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control, .input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select, .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating){
    border-radius: 5px;
    width: 25px;
border: 1px solid rgba(195,195,195, 1);


}
.imgclass{
    width: "60px" ;
    cursor: pointer;
   
}
.subheading {

    color: rgba(54, 54, 54, 1);
    font-family: "Poppins-Regular";
    font-size: 15px;
    line-height: 29px;
    
}
.txtareastyle{
    border-radius: 5px;
border: 1px solid rgba(195,195,195, 1);
opacity: 1;
background-color: rgba(255,255,255, 1);
}

.closebtnstyle{
border-radius: 6px;
color: rgba(255,255,255,1);
font-family: "Poppins-Bold";
font-size: 15px;
text-align: center;
background-color: #006ECF;
}
.mybtn {
    padding-top: 19px;
}

.mytimeweekcss {
    background-color: #006ECF;
    border-radius: 6px;
    color: rgba(255, 255, 255, 1);
    padding-left: 12px;
    padding-right: 12px;
    font-family: "Poppins-Bold";
    font-size: 15px;
    line-height: 29px;
    display: inline;
    width:120px;
    height: 37px;
}

.tlthour {

    color: rgba(54, 54, 54, 1);
    font-family: "Poppins-Regular";
    font-size: 18px;
    

}
.leftCHEVRON{
    cursor: pointer;

}
.rightCHEVRON{
    cursor: pointer;
}
.columnstyle{
color: rgba(77,77,58,1);
font-family: "Poppins-Regular";
font-size: 10px;
}
.tlthourone{

color: rgba(54,54,54,1);
font-family: "Poppins-Bold";
font-size: 15px;

}
.hour{
    font-family:"Poppins-SemiBold";
    font-size: 15px;
}
.addproject{
padding-left: 12px;
color:#006ECF;
font-family: "Poppins-Medium";
font-size: 15px;
font-weight: 500;
margin-top: 20px;
margin-bottom: 19px;



}
.holidays{

color: rgba(81,179,23,1);
font-family: "Poppins-SemiBold";
font-size: 14px;
margin-bottom: 100px;
}

.tlthourvaluestyle{

color: rgba(54,54,54,1);
font-family: "Poppins-Medium";
font-size: 15px;

}
.modelheader{
    border-bottom: none;
    padding-bottom: 0;
   
    
}
.modelheader1{
    border-bottom: none;
    padding-top: 0;
    
   
}
.addtitle{
    color: rgba(54,54,54,1);
    font-family: "Poppins-Regular";
    font-size: 25px;
    padding-left: 3%;
    
}
.addsubtitle{
color: rgba(54,54,54,1);
font-family: "Poppins-Regular";
font-size: 15px;
line-height: 29px;
padding-left: 3%;
}
.footercomment{
    border-top:none;
    padding-bottom: 5%;
}
.commenttextarea{
  
    border-radius: 5px;
border: 1px solid rgba(195,195,195, 1);
background-color: rgba(255,255,255, 1);

}
.selecthour{
    border-radius: 5px;
    border: 1px solid rgba(195,195,195, 1);
 
    background-color: rgba(255,255,255, 1);
}

.btn {
    background-color:  #006ECF;
}

